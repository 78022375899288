import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Header from "../../../Components/Header/Header";
import Footer from "../../../Components/Footer/Footer";

import { fetchAnnouncment } from "../../../Services/DataService";
import {
  transformToSmallNewsDate,
  transformImageUrl,
  getDateText,
} from "../../../Utils/Helper";

import "../news.css";

const NewsDetailsPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [announcment, setAnnouncments] = useState({ title: "", text: "" });

  let { newsId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      let announcment = await fetchAnnouncment(newsId);

      console.log(announcment);
      setAnnouncments(announcment);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  let imgUrl = "";

  if (announcment.images && announcment.images.length > 0) {
    imgUrl = transformImageUrl(announcment.images[0].url);
  }

  let date = transformToSmallNewsDate(announcment.entityCreatedAt);

  return (
    <>
      <Header />
      <main role="main">
        <div className="single-blog">
          <div className="container">
            <div className="row">
              <div className="col9">
                {isLoading ? (
                  <h3 style={{ textAlign: "center", marginTop: 20 }}>
                    Loading...
                  </h3>
                ) : (
                  <div className="blog-left-content">
                    <div className="sg-blg-image">
                      <img src={imgUrl} alt=""/>
                      <div className="blg-dt">
                        {date.day} <small>{date.month}</small>
                      </div>
                    </div>
                    <div className="blog-content">
                      <h6>Νέα</h6>
                      <h2>{announcment.title}</h2>
                      <div className="post-on">
                        Posted on{" "}
                        <span>{getDateText(announcment.entityCreatedAt)}</span>
                      </div>
                      <p>{announcment.text}</p>
                      {/* <div className="social-links">
                      <ul>
                        <li>
                          <a href="#">
                            <i className="fa fa-facebook" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-twitter" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-envelope-o" aria-hidden="true"></i>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <i className="fa fa-linkedin" aria-hidden="true"></i>
                          </a>
                        </li>
                      </ul>
                    </div> */}
                      <div className="pagination-links">
                        {announcment.nextAnnouncementId ? (
                          <a href={`/News/${announcment.nextAnnouncementId}`}>
                            <i
                              className="fa fa-chevron-left"
                              aria-hidden="true"
                            ></i>{" "}
                            Επόμενος
                          </a>
                        ) : (
                          <a>
                            <i
                              className="fa fa-chevron-left"
                              aria-hidden="true"
                            ></i>
                          </a>
                        )}
                        {announcment.previousAnnouncementId ? (
                          <a
                            href={`/News/${announcment.previousAnnouncementId}`}
                          >
                            Προηγούμενος{" "}
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        ) : (
                          <a>
                            <i
                              className="fa fa-chevron-right"
                              aria-hidden="true"
                            ></i>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="col3">
                <div className="blog-sidebar">
                  <div className="widget-rep">
                    <h3>Νέα και Ανακοινώσεις</h3>
                    <p>
                      Με ευαισθησία στις ανάγκες και τα ενδιαφέροντα της τοπικής
                      μας κοινότητας, δεσμευόμαστε να σας παρέχουμε έγκυρες και
                      ενδιαφέρουσες πληροφορίες.
                    </p>
                  </div>
                  {/* <div className="widget-rep">
                    <h3>Kατηγορίες</h3>
                    <ul>
                      <li>
                        <a href="#">Ανακοινώσεις</a> <span>(2)</span>
                      </li>
                      <li>
                        <a href="#">Νέα </a> <span>(4)</span>
                      </li>
                    </ul>
                  </div>
                  <div className="widget-rep">
                    <h3>Ιστορικό</h3>
                    <ul>
                      <li>
                        <a href="#">Ιανουάριος 2024</a> <span>(4)</span>
                      </li>
                      <li>
                        <a href="#">Δεκέμβριος 2013 </a> <span>(1)</span>
                      </li>
                      <li>
                        <a href="#">Αύγουστος 2013 </a> <span>(1)</span>
                      </li>
                    </ul>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer />
    </>
  );
};

export default NewsDetailsPage;
