import axios from 'axios'

const BASE_URL = process.env.REACT_APP_API_URL;
const ORG_ID = process.env.REACT_APP_ORG_ID

export const fetchHomePage = async () => {
  let url = `${BASE_URL}/Homepage/GetHomepageData`;

  let homepageResult = await fetchData(url);

  return homepageResult ?? {};
}

export const fetchNews = async (pageNumber=0, pageSize=0) => {
  let url = `${BASE_URL}/Announcement/GetAnnouncements`;

  if(pageNumber !== 0){
    url += `?PageNumber=${pageNumber}&PageSize=${pageSize}`
  }
  let announcementResult = await fetchData(url);

  return announcementResult ?? [];
};


export const fetchAnnouncment = async (newsId) => {
  let url = `${BASE_URL}/Announcement/GetAnnouncementById/${parseInt(newsId)}`;

  let announcementResult = await fetchData(url);

  return announcementResult ?? null;
}


const fetchData = async (url) => {
  let result = await axios.get(url, {params: {organizationId:ORG_ID}}).catch(ex => console.log(ex));

  if(result && result.status === 200 && result.data.success){
    return result.data.result;
  }

  return null;
}