import { transformToSmallNewsDate,transformImageUrl } from "../../Utils/Helper";
import { Link } from "react-router-dom";

const News = ({announcment}) => {

  let date = transformToSmallNewsDate(announcment.entityCreatedAt);

  let imgUrl = "";
  let description = announcment.text;

  if(announcment.images && announcment.images.length > 0){
    imgUrl = transformImageUrl(announcment.images[0].url);
  }

  if(description.length >= 100){
    description = description.substring(0,100) + "...";
  }

  return (
    <Link to={`/News/${announcment.id}`} className="news-bx">
      <div className="news-img">
        <img src={imgUrl} alt=""/>
        <div className="date-ns">
          {date.day} <small>{date.month}</small>
        </div>
      </div>
      <div className="news-content">
        <h3>{announcment.title}</h3>
        <p>{description}</p>
      </div>
    </Link>
  );
};

export default News;
