import React from "react";

import "./news.css";

import News from "./News";

const NewsSection = ({news, isLoading, newsClass}) => {
  return (
    <>
      {isLoading ? (
        <h3 style={{ textAlign: "center", marginTop: 20 }}>Loading...</h3>
      ) : (
        <div className={newsClass}>
          <div className="row">
            {news && news.map((announcment) => {
              return (
                <div className="col4"  key={announcment.id}>
                  <News
                    announcment={announcment}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default NewsSection;
